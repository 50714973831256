import React from "react";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import Hero from "../components/Templates/Hero";
import Link from "../components/Global/Link";

// Markup
const Contact = () => {
  const openGraph = {
    type: "website",
    title: "Contact | CP4RP",
    socialTitle: "All the details for Claire & Richy's big day",
    description: "20th May 2023 | Left Bank, Leeds",
  };

  return (
    <Layout>
      <Meta openGraph={openGraph} />
      <Hero title="Contact" colour="black" />
      <section className="py-5 brand-background-black brand-section-min-height">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 brand-text-white text-center">
              <p className="fs-4">
                If you have any questions or just fancy a chat, then speak to
                Claire on:
              </p>
              <p className="brand-font fs-1 mb-5">07971465129</p>
              <p>
                <Link
                  button
                  to="mailto:clairepittaway@hotmail.co.uk"
                  icon
                  colour="pink"
                >
                  clairepittaway@hotmail.co.uk
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Contact;
